import { RootState, store} from '../../Redux';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Configs from "src/Config";


const BASE_URL = Configs.PUBLIC_API_HOST;

const baseQuery= fetchBaseQuery({
   baseUrl: BASE_URL,
   prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      const isAuth= (getState()as RootState).auth.isAuthenticated;

      if (token) {
         headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
   },
 validateStatus : (response,result)=> {

   if (response.status === 401) {
      console.log('Unauthorized. Logging out...');
  }
   return response.status === 200 && !result.isError;
 }
 
});

export const baseApi = createApi({
   baseQuery,
   keepUnusedDataFor: 0,
   endpoints: () => ({}),

});
