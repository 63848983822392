import { Dayjs } from "dayjs";
import moment from "moment";

type AnyFunction = (...args: any[]) => any;

type GroupedData = {
    [key: string]: any[];
}

type SortKey<T> = keyof T | ((item: T) => any) | string;
type SortDirection = 'asc' | 'desc';
type SortConfig<T> = [SortKey<T>, SortDirection];
type Comparator = (a: any, b: any, direction: SortDirection) => number;



export const calculateAge = (birthDate: string): number | string => {
    const today = moment();
    const birthDateObj = moment(birthDate);
    const age = today.diff(birthDateObj, 'years');
    if (isNaN(age)) {
        return 'N/A';
    }
    return age;
}

/**
 * Converts a timestamp to a formatted date string.
 * @param {string} timestamp - The timestamp string to convert to date.
 * @returns {string} The formatted date string in the format "dd/mm/yyyy hh:mm".
 */
export const timeStampToDate = (timestamp: string) => {
    const myDate = new Date(timestamp);

    // Extract components
    const day = String(myDate.getDate()).padStart(2, "0");
    const month = String(myDate.getMonth() + 1).padStart(2, "0");
    const year = myDate.getFullYear();
    const hours = String(myDate.getHours()).padStart(2, "0");
    const minutes = String(myDate.getMinutes()).padStart(2, "0");

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    return formattedDate
}


/**
 * Converts a time string to a formatted time string with AM/PM.
 * @param {string} timeString - The time string to convert (format: "hh:mm AM/PM").
 * @returns {string} The formatted time string in the format "hh:mm AM/PM".
 */
export const convertTimeStringToDate = (timeString: any) => {
    const [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
    const momentObj = moment({ hours, minutes });
    const momentString = momentObj.format("hh:mm A");

    return momentString;
};

export const convertTimeString = (timeString: any) => {
    const [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":");

    // Convert hours to 24-hour format if needed
    if (period === "PM" && parseInt(hours, 10) !== 12) {
        hours = (parseInt(hours, 10) + 12).toString();
    } else if (period === "AM" && parseInt(hours, 10) === 12) {
        hours = "00";
    }

    // Construct a new Date object
    const dateObject = new Date();
    dateObject.setHours(parseInt(hours, 10), parseInt(minutes, 10));

    return dateObject;
};

export const convertTo24HourFormat = (timeString: any) => {
    const [hour, minute] = timeString.split(':');
    const period = timeString.slice(-2);
    let formattedHour = period.toLowerCase() === 'pm' ? parseInt(hour, 10) + 12 : hour;
    return `${formattedHour}:${minute}`;
};



export const converToProperNumber = (num: number) => {
    if (Number.isInteger(num)) {
        return Math.round(num);
    } else {
        return parseFloat(num.toFixed(2));
    }
}


/**
 * Calculate progress percentage based on success number, total number of trials,
 * base line success number, and base line trial number.
 * 
 * @param {number} successNumber The number of successful trials.
 * @param {number} trialNumber The total number of trials.
 * @param {number} baseSuccessLineNumber The base line success number.
 * @param {number} baseLineTrialNumber The base line trial number.
 * @returns {number} The progress percentage.
 */

export const calculateProgressPercentage = (successNumber: number, trialNumber: number, baseSuccessLineNumber: number, baseLineTrialNumber: number): string | number => {
    // // Calculate success rate for current scenario and baseline scenario
    // const currentSuccessRate = (successNumber / trialNumber) * 100;
    // const baselineSuccessRate = (baseSuccessLineNumber / baseLineTrialNumber) * 100;

    // // Calculate percentage change
    // const percentageChange = ((currentSuccessRate - baselineSuccessRate) / baselineSuccessRate) * 100;
    // if (Number.isNaN(percentageChange) || percentageChange === Infinity) return "-";

    // return converToProperNumber(percentageChange);
    const percentageChange = ((successNumber / trialNumber) - (baseSuccessLineNumber / baseLineTrialNumber)) * 100;
    if (Number.isNaN(percentageChange) || percentageChange === Infinity) return "-";
    return converToProperNumber(percentageChange);
    // reportEntry.success_number / reportEntry.trial_number -
    //             baselineSuccessNumber / baselineTrialNumber)
}



/**
 * Calculates the height based on the given width and aspect ratio.
 * @param {number} width - The width of the element.
 * @param {number} aspectRatio - The aspect ratio (width/height) of the element.
 * @returns {number} The calculated height based on the provided width and aspect ratio.
 */
export const calculateHeight = (width: number, aspectRatio: number) => {
    return width / aspectRatio;
}


/* Lodash like Functions */



const defaultComparator: Comparator = (a, b, direction) => {
    if (a === b) return 0;
    if (a === null || a === undefined) return direction === 'asc' ? 1 : -1;
    if (b === null || b === undefined) return direction === 'asc' ? -1 : 1;
    if (a < b) return direction === 'asc' ? -1 : 1;
    if (a > b) return direction === 'asc' ? 1 : -1;
    return 0;
};

// const resolvePropertyValue = (obj: any, path: string): any => {
//     const properties = path.split(/[\.\[\]]/).filter(p => p);
//     return properties.reduce((acc, prop) => acc && acc[prop], obj);
// };

const resolvePropertyValue = (obj: any, path: string): any => {
    const properties = path.split(/[\.\[\]]/).filter(p => p);
    let result = obj;

    for (let i = 0; i < properties.length; i++) {
        if (result == null) {
            return undefined;
        }
        result = result[properties[i]];
    }

    return result;
};

export const customSortBy = <T>(array: T[], sortConfigs: SortConfig<T>[]): T[] => {
    return [...array].sort((a, b) => {
        for (let [key, direction] of sortConfigs) {
            let valA = typeof key === 'function' ? key(a) : resolvePropertyValue(a, key as string);
            let valB = typeof key === 'function' ? key(b) : resolvePropertyValue(b, key as string);
            const result = defaultComparator(valA, valB, direction);
            if (result !== 0) return result;
        }
        return 0;
    });
};

export const uniq = <T>(array: T[]): T[] => {
    // Use a Set to store unique elements
    const uniqueSet = new Set<T>(array);
    // Convert the Set back to an array
    const uniqueArray = Array.from(uniqueSet);
    return uniqueArray;
};

export const customDebounce = <T extends AnyFunction>(func: T, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return (...args: Parameters<T>) => {
        const context = this as ThisParameterType<T>;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
};

// export const customGroupBy = <T>(arr: T[], key: keyof T): GroupedData =>
//     arr.reduce((acc: GroupedData, obj: T) => {
//         const keyValue = obj[key] as string | number;
//         if (!acc[keyValue]) {
//             acc[keyValue] = [];
//         }
//         acc[keyValue].push(obj);
//         return acc;
//     }, {});

export const customGroupBy = <T>(arr: T[], key: keyof T): GroupedData => {
    const result: GroupedData = {};

    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];
        const keyValue = obj[key] as string | number;

        if (!result[keyValue]) {
            result[keyValue] = [];
        }
        result[keyValue].push(obj);
    }

    return result;
}

// export const customGetAverage = <T extends Record<PropertyKey, number>>(arr: T[], property: keyof T): number => {
//     const sum = arr.reduce((acc, obj) => acc + obj[property], 0);
//     return sum / arr.length;
// }

export const customGetAverage = <T extends Record<PropertyKey, number>>(arr: T[], property: keyof T): number => {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
        sum += arr[i][property];
    }
    return arr.length > 0 ? sum / arr.length : 0;
}


export const customChunkArray = <T>(arr: T[], chunkSize: number): T[][] => {
    const chunks: T[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
};

export const customflattenDeep = (arr: any[]): any[] => {
    const flattenedArray: any[] = [];

    function flattenHelper(input: any[]) {
        for (const item of input) {
            if (Array.isArray(item)) {
                flattenHelper(item);
            } else {
                flattenedArray.push(item);
            }
        }
    }

    flattenHelper(arr);

    return flattenedArray;
}


export function classNames(predefinedClasses: string, classObj: { [key: string]: boolean }): string {
    const dynamicClassString = Object.keys(classObj)
        .filter(className => classObj[className])
        .join(' ');

    return `${predefinedClasses} ${dynamicClassString}`.trim();
}

export function capitalizeFirstLetter(str: string) {
    return str.replace(/\b\w/g, function (char: string) {
        return char.toUpperCase();
    });
}

export function convertMinutesToHoursAndMinutes(minutes: number) {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    let result = '';
    if (h > 0) {
        result += `${h} hour${h !== 1 ? 's' : ''}`;
    }
    if (m > 0) {
        if (result) result += ' ';
        result += `${m} minute${m !== 1 ? 's' : ''}`;
    }
    return result || '0 minutes';
}

export function convertHoursToHoursAndMinutes(hours: number) {
    const h = Math.floor(hours);
    const m = Math.round((hours - h) * 60);
    let result = '';
    if (h > 0) {
        result += `${h} hour${h !== 1 ? 's' : ''}`;
    }
    if (m > 0) {
        if (result) result += ' ';
        result += `${m} minute${m !== 1 ? 's' : ''}`;
    }
    return result || '0 minutes';
}

export const getStartOfWeek = (date: Date) => {
    const start = new Date(date);
    start.setDate(date.getDate() - date.getDay());
    return start;
};

export const getDaysOfWeek = (startDate: Date) => {
    return Array.from({ length: 7 }, (_, i) => {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        return date;
    });
};

export const calculateActiveTime = (start: Dayjs | null, end: Dayjs | null, breakTime: string) => {
    if (!start || !end) return 0;
    const breakDuration = parseInt(breakTime, 10) || 0;
    const activeTime = (end.diff(start, 'minute')) - breakDuration;
    return activeTime > 0 ? activeTime : 0;
};


export   //MuiDatePicker : disable every Saturday and Sunday
    const isWeekend = (date: any) => {
        const day = date.getDay();
        return day === 0 || day === 6;
    };






